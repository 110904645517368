@tailwind base;
@tailwind components;
@tailwind utilities;

/* styles to hide arrows on number input */
@layer base {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.btn {
	@apply font-normal
}

.btn-primary {
	@apply text-white
}

.btn-outline {
	@apply border-primary
}

.btn-error {
	@apply bg-red-800 text-white border-red-800 hover:bg-red-700 hover:border-red-700
}
